/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

require('bootstrap');
require('admin-lte');

const moment = require('moment/moment.js');
global.moment = global.Moment = moment;


require('chart.js');
require('@fortawesome/fontawesome-free/css/all.min.css');

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});